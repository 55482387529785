<script setup>
import { onMounted } from "vue";
import useWarehouses from "@/hooks/useWarehouses";
import { definePage } from "vue-router/auto";
import { useRoute } from "vue-router";

const { fetchDashboardData, dashboardData, fetchWarehouse, warehouse } = useWarehouses();
const route = useRoute();

definePage({
  meta: {
    layout: "EmptyLayout",
  },
});

onMounted(() => {
  fetchWarehouse(route.params.id);
  fetchDashboardData(route.params.id);
});

const doorColor = (door) => {
  return door.closed ? "error" : "success";
};
</script>

<template>
  <v-card
    flat
    class="bg-black text-white"
    style="border-bottom: 1px solid #fff"
    v-if="warehouse"
  >
    <v-card-title class="text-h4 text-uppercase text-center d-flex justify-space-between">
      <div class="justify-self-center">{{ warehouse.data.nickname }} Dashboard</div>

      <div class="justfy-self-end">
        <v-btn
          color="black"
          class="px-4 py-1 float-right"
          :to="`/warehouses/${warehouse.data.id}`"
        >
          Back to Connect
        </v-btn>
      </div>
    </v-card-title>
  </v-card>
  <v-table class="bg-black">
    <thead>
      <tr>
        <th class="text-center text-h7 text-uppercase">Open/Occupied/Closed</th>
        <th class="text-center text-h5 text-uppercase text-no-wrap">Door</th>
        <th class="text-center text-h5 text-uppercase text-no-wrap">Truck #</th>
        <th class="text-left text-h5 text-uppercase text-no-wrap">Load</th>
        <th class="text-center text-h5 text-uppercase text-no-wrap">Status</th>
        <th class="text-center text-h5 text-uppercase text-no-wrap">Est Departure</th>
        <th class="text-center text-h5 text-uppercase text-no-wrap">Staging Info</th>
      </tr>
    </thead>
    <tbody class="text-table">
      <tr
        class="text-table"
        v-for="door in dashboardData.data"
        :key="door.id"
        v-if="dashboardData"
      >
        <td>
          <div class="lights">
            <div class="light">
              <div :class="{ inner: true, open: !door.occupied && !door.closed }"></div>
            </div>
            <div class="light">
              <div :class="{ inner: true, occupied: door.occupied }"></div>
            </div>
            <div class="light">
              <div :class="{ inner: true, closed: door.closed }"></div>
            </div>
          </div>
        </td>
        <td>{{ door.nickname }}</td>
        <td>
          {{ door.truck_number }}
          <span v-if="door.trailer_number">, {{ door.trailer_number }}</span>
        </td>
        <td>
          <div
            v-for="load in door.loads"
            :key="load.po_number"
            class="text-no-wrap"
            style="border-bottom: 1px solid #282828; margin: 0.2rem"
          >
            {{ load.id }} <small>{{ load.skus.join(", ") }}</small>
          </div>
        </td>
        <td>
          <v-chip size="lg" :color="doorColor(door)" class="px-4 py-1">{{
            door.closed ? "CLOSED" : "OPEN"
          }}</v-chip>
        </td>
        <td class="text-center">10 MIN</td>
        <td class="text-no-wrap">
          <div>{{ door.staging_count }}</div>
          <div v-for="stage in door.staging">
            {{ stage.id }}
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<style>
.app-class {
  background-color: #000;
}
.text-table {
  font-size: 1.3rem !important;
}

.lights,
.door-number,
.truck-number,
.arrived-at,
.load,
.departing-at,
.staging-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lights {
  width: 100px;
}

.door-number {
  /* background: red; */
  /* justify-content: center; */
  width: 70px;
  margin-left: 3rem;
  margin-right: 1rem;
  font-size: 3rem;
  font-weight: 500;
}

.load {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  margin-left: 2rem;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 500;
}

.load .sku {
  display: bl;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.truck-number {
  width: 200px;
  text-align: center;
  font-size: 2rem;
  margin-left: 3rem;
}

.staging-info {
  width: 200px;
}

.departing-at {
  margin-left: 3rem;
  width: 200px;
  font-size: 2rem;
  font-weight: 500;
}

.departing-at small {
  font-size: 1rem;
  font-weight: 400;
}

.lights .light {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #efefef;
  padding: 3px;
}

.light .inner {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.light .inner.open {
  background: #88b04b !important;
}

.light .inner.occupied {
  background: #0066ff !important;
}

.light .inner.closed {
  background: #ff3333 !important;
}

.load {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.in-staging {
  display: flex;
  background: #efefef31;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  font-size: 1.75rem;
  line-height: 2rem;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
}

.in-staging small {
  padding-top: 0.3rem;
  font-size: 1.25rem;
  line-height: 1rem;
}

@keyframes blink-bg {
  0%,
  100% {
    background-color: #88b04b;
  }

  50% {
    background-color: #ff8800;
  }

  /* You can replace this with another color */
}

.blink-bg {
  animation: blink-bg 1s linear infinite;
}
</style>
